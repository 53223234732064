.sendMessage {
  width: 100%;
  display: flex;
  align-items: flex-start;

  .message {
    width: calc(100% - 125px);
  }

  .btnSend {
    width: 101px;
    margin-left: auto;

    &:disabled {
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .sendMessage {
    .message {
      width: calc(100% - 117px);
    }
  }
}

@media (max-width: 480px) {
  .sendMessage {
    .message {
      width: calc(100% - 76px);
    }

    .btnSend {
      width: 60px;
      padding: 6px 8px;
    }
  }
}

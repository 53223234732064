@import '../variables';

.paginate-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .paginate {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .paginate.disabled {
    button,
    a {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  .page-list {
    list-style: none;
    display: inline-flex;

    li {
      a {
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #000;
        margin: 0 3px;
        cursor: pointer;
        font-size: 14px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }

      .active {
        opacity: 1;
        font-weight: 600;
      }
    }
  }

  .page-prev,
  .page-next {
    border: none;
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: transparent;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.viewFullScreen {
  display: flex !important;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-content {
    max-height: 90vh;
    border-radius: 0;
    border: none;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

  .modal-body {
    .viewImg {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 80vh;
      }
    }
  }
}

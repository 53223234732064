@import '../_variables';

.header {
  // -webkit-box-shadow: 0px 1px 2px 0px rgba(194, 194, 194, 1);
  // -moz-box-shadow: 0px 1px 2px 0px rgba(194, 194, 194, 1);
  // box-shadow: 0px 1px 2px 0px rgba(194, 194, 194, 1);
  border: none;
  background: none;

  button.header-toggler {
    width: 38px;
    height: 38px;
    background: #fff;
    border-radius: 4px;
    padding: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .logoText {
    color: $primary;
    margin: 0;
    font-size: 20px;
  }
}

@media (max-width: 991px) {}
.footer {
  background-color: #f6fbfc;
  position: relative;
  display: flex;
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }

  .imgPerson {
    position: absolute;
    bottom: 50px;
    &.person1 {
      left: 10%;
    }
    &.person2 {
      right: 10%;
    }
  }
  .imgBush {
    position: absolute;
    bottom: 0;
    right: 20%;
    height: 200px;
  }
  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 200px;
  }
  .subscribe {
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0 20px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    gap: 10px;
    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }

    a {
      text-decoration: none;
      color: var(--cui-secondary);
      &:hover {
        color: var(--cui-primary);
      }
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  @media screen and (max-width: 768px) {
    &.hasControls {
      margin-bottom: 20px;
    }
  }

  .status {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    > span {
      color: white;
      border-radius: 30px;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  .prevButton,
  .nextButton {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 45%;
    width: 60px;
    height: 60px;
    display: none;
    @media screen and (min-width: 769px) {
      display: flex;
      width: 40px;
      height: 40px;
      top: 40%;
    }
  }
  .prevButton {
    left: 40px;
    @media screen and (max-width: 768px) {
      left: 20px;
    }
  }
  .nextButton {
    right: 40px;
    @media screen and (max-width: 768px) {
      right: 20px;
    }
  }

  .slide {
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
    }
  }
}

@import '../../scss/variables';

.sidebar {
  transition: width 0.4s ease-in;
  @media screen and (min-width: 769px) {
    width: $width-sidebar;
    &.expand {
      width: $width-sidebar-expand;
    }
  }
}

.toggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  transform: rotate(0deg);
  transition: all 0.5s;

  &.rotate {
    transform: rotate(180deg);
  }
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    white-space: nowrap;
    margin-left: 24px;
  }
}

// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

@import './core/index';
// If you want to add custom CSS you can put it here.
@import 'custom';

body {
  // font-family: 'Inter', sans-serif;
  font-family: 'Roboto', sans-serif;
  background: white;
  color: var(--cui-secondary);
}
p {
  margin: 0;
}

img {
  object-fit: cover;
}

.threeDots {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
/* CoreUI CAvatar */
.avatar {
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}
.font-semibold {
  font-weight: 600;
}
.font-medium {
  font-weight: 500;
}

.storeLogo {
  width: auto !important;
  height: 100px;
  text-align: center;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 6px;

  img {
    height: 100%;
    width: auto !important;
    object-fit: contain;
  }
}

.viewImageTable {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.imgFeedback {
  max-width: 100px;
  max-height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-in;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.phoneInput {
  font-size: 16px !important;

  .form-control {
    font-size: 16px !important;
    height: 38px !important;
    border-color: #b1b7c1 !important;
    border-radius: 6px !important;
    width: 100% !important;
  }

  .flag-dropdown {
    background-color: #d9dbe1 !important;
  }
}

.phoneInput.inputError {
  .flag-dropdown {
    border-color: #e55353 !important;
  }
  .form-control {
    border-color: #e55353 !important;
  }
}

.selectStore {
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  appearance: none !important;

  &::-ms-expand {
    display: none !important;
  }
}

.publicLayout {
  min-height: 100vh;
  position: relative;
  transform: all 0.3s;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(44, 56, 74, 0.95);
    border: 1px solid #b1b7c1;
    border-radius: 0.375rem;

    &:disabled {
      background-color: #f2f2f2;
      border-color: #b1b7c1;
    }
  }
}

.datePickerBox {
  width: 100%;

  label {
    font-size: 16px;
    margin-bottom: 0.6rem;
  }

  p.invalid {
    margin-top: 4px !important;
  }
}

.datePickerBox.error {
  .react-datepicker-wrapper {
    input {
      border-color: #e55353;
    }
  }
}

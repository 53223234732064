// Here you can add other styles
@import './variables';

.table > :not(caption) > * > * {
  padding: 10px 15px;
}

.shadow-md {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.input-group {
  .input-group-text {
    background-color: white;
  }
}

.modal-md {
  max-width: 520px;
}

.popover-body {
  padding: 0;
}

a.text-dark {
  transition: 0.3s color;
  &:hover {
    color: var(--cui-primary) !important;
  }
}

a.text-secondary {
  transition: 0.3s color;
  &:hover {
    color: var(--cui-primary) !important;
  }
}

.sidebar-backdrop {
  pointer-events: none;
}

.textRating {
  color: #f59e0b;
  font-weight: 600;
}

.bg-text-highlight {
  width: fit-content;
  background-color: rgba(16, 178, 238, 0.2);
}

.tab-nav {
  position: relative;
  .nav-item {
    margin-right: 16px;

    a {
      font-size: 14px;
      font-weight: 500;
      border: 1px dashed #e1e3ea;
      background: #fff;
      color: #3f4254;
    }

    a.active {
      border: 1px solid #e1e3ea;
      background: #fff;
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
}

.uploadFile {
  min-width: 100px !important;
  min-height: 70px !important;
  width: 100% !important;

  .file-types {
  }
}

.form-title {
  margin-bottom: 5px;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 5px;
}

.actions {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  button {
    padding: 0;
    border: none;
    color: #333;

    &:hover {
      background: none;
      color: rgb(21, 108, 189);
      border: none;
    }
  }
}

.form-control,
.form-select {
  &:disabled {
    background-color: #f2f2f2;
  }
}

.btn-sm {
  padding: 4px 10px;
  font-size: 13px;
}
.btn-success {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-danger {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-primary {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:disabled {
    color: #fff;
  }
}

.btn-info {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-link {
  &:hover {
    color: $primary;
    opacity: 0.7;
  }
}

.btn-ghost-primary {
  &:hover {
    color: #fff;
  }
}

.table-title {
  font-size: 20px;
  margin-bottom: 0;
  margin-right: auto;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.2s;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  &.sidebarOpen {
    width: calc(100% - var(--cui-sidebar-occupy-start, 0));
  }
  &.atTop {
    background: transparent;
    transform: translateY(0) !important;
    box-shadow: none;
  }
  @media screen and (min-width: 769px) {
    &.hide {
      transform: translateY(-100%);
    }
  }
  .navbar-collapse {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  }

  .navbar-brand {
    color: $primary;
  }

  .nav-link {
    color: var(--cui-secondary);
    font-weight: 500;

    &:hover {
      color: $primary;
    }
  }

  .nav-link.active {
    font-weight: 500;
    color: $primary;
  }
}

@media (max-width: 991px) {
  .publicLayout {
    .navbar {
      .navbar-collapse {
        background: #fff;
        padding: 20px;
        margin-top: 10px;
        text-align: right;

        ul {
          margin: 0 0 !important;

          li {
            margin-bottom: 16px;
            text-align: left;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              margin: 0 !important;
              text-align: right;
              display: inline-block;
              padding: 0;
            }
          }
        }

        // ul.header-nav {
        //   margin: 0 !important;
        //   text-align: right;
        // }
      }

      .subNav {
        margin-left: auto !important;

        li.nav-item {
          margin-right: 16px !important;
        }

        .btn {
          margin-right: 16px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .myProfile {
    h1 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  .myPassword {
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 480px) {
  .tab-nav {
    .nav-item {
      margin-right: 8px;

      a {
        padding: 6px 10px;
      }
    }
  }

  .table {
    thead {
      th {
        font-size: 14px;
      }
    }

    tbody {
      td {
        font-size: 14px;
      }
    }
  }

  .table-title {
    font-size: 18px;
  }

  .table > :not(caption) > * > * {
    padding: 6px 10px;
  }

  .customerProgress {
    display: block;
    margin-bottom: 10px !important;

    .progress-group-prepend {
      text-align: left !important;
    }
  }

  .myProfile {
    h1 {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .myPassword {
    h2 {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .publicLayout {
    .header {
      .navbar-brand {
        font-size: 16px;
      }
    }

    button.navbar-toggler {
      padding: 3px;

      .navbar-toggler-icon {
        width: 24px;
        height: 24px;
      }
    }

    .navbar {
      .subNav {
        align-items: center;

        li.nav-item {
          margin-right: 10px !important;
          .btn {
            margin-right: 0 !important;
          }
        }
        .btn {
          margin: 0;
          margin-right: 10px !important;
        }
      }
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.2s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s;
}

.slick-prev,
.slick-next {
  width: 60px !important;
  height: 60px !important;
  border-radius: 40px !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  @media screen and (max-width: 768px) {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--cui-primary) !important;
  }
}

.slick-prev {
  left: 40px !important;
}
.slick-next {
  right: 40px !important;
}

.vertical-timeline-element-icon svg {
  left: unset !important;
  top: unset !important;
  margin-left: unset !important;
  margin-top: unset !important;
}

.vertical-timeline.vertical-timeline--two-columns:before {
  background-color: var(--cui-primary);
}

.menuNotification {
  max-height: 500px;
  padding-top: 30px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
    // border: 2px solid #555555;
  }

  p {
    margin: 0;
  }

  .notifyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-bottom: 1px solid #ddd;

    h6 {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    a {
      margin-left: auto;
      font-size: 14px;
    }
  }

  > div.notifyItem {
    display: flex;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #c2c2c2;
    background: #fff;
    border-left: 3px solid #52c5f7;

    &:hover {
      background: #f8f8f8;
      cursor: pointer;
    }

    &:last-child {
      border: none;
      font-size: 14px;
    }

    h5 {
      text-transform: capitalize;
      font-size: 16px;
    }
  }

  .emptyNotify {
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .menuNotification {
    max-height: 340px;
    padding-top: 38px;

    .notifyHeader {
      padding: 8px 10px;
    }

    div.notifyItem {
      display: block;
      padding: 6px 10px;
    }

    .time {
      width: 100%;
      text-align: left;
    }
  }
}

@import '../variables';
$sidebar: #fff;
$sidebar-link: #333;
$sidebar-link-active: #000;

.sidebar {
  background: $sidebar;
  color: #000;
  // -webkit-box-shadow: 1px 0px 2px 0px rgba(194, 194, 194, 1);
  // -moz-box-shadow: 1px 0px 2px 0px rgba(194, 194, 194, 1);
  // box-shadow: 1px 0px 2px 0px rgba(194, 194, 194, 1);
  // border-right: 1px solid #ddd;
}

.sidebar-brand {
  background: #fff;
  border-bottom: 1px solid #ddd;

  h3 {
    color: $primary;
    margin: 0;
    font-size: 24px;
    // text-transform: uppercase;
  }

  svg {
    color: $primary;
  }
}

.sidebar-toggler {
  background-color: #fff;
  color: #000;

  &:hover {
    background-color: #fff;
    opacity: 0.7;
    color: #000;
  }
}

.sidebar-nav {
  .nav-link {
    color: $sidebar-link;

    &:hover {
      color: $primary;

      .nav-icon {
        color: $primary;
      }
    }
  }
  .nav-link.active {
    color: $primary;

    .nav-icon {
      color: $primary;
    }
  }
  .nav-icon {
    color: $sidebar-link;
  }
}

.footer {
  background-color: #f8f8f8;
}

@media (max-width: 991px) {
  .sidebar {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // z-index: 1031;
  }
}
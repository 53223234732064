.votePill {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(138, 147, 162, 0.1);
  padding: 4px 0;
  &.vertical {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  button {
    text-decoration: none;
    line-height: 20px;
  }
}

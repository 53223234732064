.topicImages {
  width: 200px;
  height: 200px;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
}

.emptyBg {
  background-color: #daf6fc;
  height: 300px;
  margin-top: -150px;
  margin-bottom: -150px;
}

@import '../variables';

.searchBox {
  form {
    input {
      border-radius: 4px 0 0 4px;
      border-color: #b1b7c1;
      width: calc(100% - 38px);
    }
  }

  .btn {
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 2;
    border: none;
    border: 1px solid #b1b7c1;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 0;
    height: 38px;
    width: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    opacity: 1;

    &:hover {
      background: none;
      color: #000;
      // opacity: 0.7;
    }
  }
}

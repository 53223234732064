@keyframes uploading-arrow {
  0% {
    margin-bottom: -30%;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    margin-bottom: 10%;
  }
}

@keyframes uploading-arrow-complete {
  from {
    margin-bottom: -30%;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
    fill: rgb(108, 117, 125);
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  display: inline-block;

  svg {
    position: absolute;

    &.uploadBox {
      bottom: -7px;
    }

    &.uploadArrow {
      left: 8px;
      position: absolute;
      opacity: 0;
      bottom: 20%;
      animation: uploading-arrow 1.5s ease-out infinite;
    }
  }
  &.disabled {
    .uploadArrow {
      animation: uploading-arrow-complete 1.5s ease-out 1;
      transition: fill 1.5s ease-out;
    }
    svg {
      opacity: 1;
    }
    path {
      fill: rgb(108, 117, 125);
    }
  }
}

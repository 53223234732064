// @import './variables';

.wrapper {
  @media screen and (max-width: 768px) {
    width: 100%;
    @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding 0.15s);
  }
  @media screen and (min-width: 769px) {
    // z-index: 1031;
    transition: padding-left 0.4s ease-in;
    padding-left: $width-sidebar;
    &.sidebar-expand {
      padding-left: $width-sidebar-expand;
    }
    &.sidebar-hide {
      padding-left: 0;
    }
  }
}
